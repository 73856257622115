<template>
  <div class="mine-page">
    <van-empty :description="word" v-if="liveList.length == 0" />
    <div class="live-list" v-if="liveList.length > 0">
      <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onRefresh"
      >
        <div v-for="(item,index) in liveList" :key="index"  class="live-card gray-card" @click="toBVue('/liveDetail', item.live_trailer_id)">
          <img :src="item.cover" alt="">
          <div>
            <div v-if="item.credit" class="card-horn">学分</div>
            <div class="card-title">
              <div class="card-title-time">
                <div class="tag">时间</div>
                <p class="time">{{item.timeShow}}</p>
              </div>
              <p>主讲：{{item.speaker}}</p>
            </div>
            <p class="live-desc ellipsis line2">{{item.title}}</p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
export default {
  name: 'Mine',
  data(){
    return {
      liveList:[],
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false,
      formData: {
        limit: 10,
        offset: 0
      },
    }
  },
  created() {
    this.live()
  },
  mounted() {
  },
  methods:{
    onRefresh() {
      this.live()
      this.loading = false;
    },
    live() {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      if (that.formData.offset == 0) {
        that.liveList = []
      }
      that.refreshStatus = true
      this.$api.LIVE_SIGN_GET(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.refreshStatus = false
          that.finished = true
        }
        if (res.length === 0) {
          that.refreshStatus = false
          that.word = '暂无直播订阅'
          return false
        }
        res.forEach((item) => {
          that.liveList.push({
            live_trailer_id: item.live_trailer_id,
            title: item.title,
            speaker: item.speaker,
            credit: item.credit ? true : false,
            cover: item.cover ? item.cover : '',
            timeShow: item.start_time + '-' +item.end_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.refreshStatus = false
      })
    },
    toBVue(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    }
  }
}
</script>

<style lang='less' scoped>
  .live-card{
    width:calc(100% - 24px);
    padding:12px;
    display:flex;
    align-items: center;
    margin-left:0;
    margin:16px 0;
  }
  .live-card img{
    display:block;
    width:146px;
    margin-right:14px;
    max-height:100%;
  }

  .live-card .live-desc{
    font-size:14px;
    padding-top:8px;
    color:#000;
  }

  .live-card .time {
    font-size:15px;
  }

  .live-card .card-horn{
    font-size:10px;
  }
  .live-no-data{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-100%);
    img{
      display:block;
      margin:0 auto;
      width:68px;
      max-height:100%;
    }
    p{
      font-size:14px;
      color:#E5E5E5;
      text-align: center;
      margin-top:15px;
    }

    .live-more{
      height:32px;
      border-radius:32px;
      margin-top:15px;
    }
  }
  
</style>
